import * as React from "react"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => {
  const breakpoints = useBreakpoint();
  return (
    <Layout breakpoints={breakpoints}>
      <Seo title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage
